import useOrganisation from "@/scripts/hooks/useOrganisation";
import useUserProfile from "@/scripts/hooks/useUserProfile";
import { user } from "firebase-functions/lib/providers/auth";
import { useRouter } from "next/router";
import { useEffect } from "react";
export default function AppPage() {
  const router = useRouter();
  const { profile } = useUserProfile();
  useEffect(() => {
    if (profile?.organisationId )   {
      router.push(`/app/${profile.organisationId}/home`);
    }
  }, [profile]);
  return <></>;
}
